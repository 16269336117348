import React, { useState } from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import Spinner from "../components/spinner"
import aboutMeStyles from "./about-me.module.scss"
import cbtSessionsStyles from "./cbt-sessions.module.scss"

const AboutMePage = () => {
  const [vidLoaded, setVidLoaded] = useState(false)
  return (
    <Layout isHome={false}>
      <Head
        title="About Me"
        description="A therapist bringing a radical anti-oppressive framework to Cognitive Behavioural Therapy (CBT) counselling."
      />
      <h2>About Me</h2>
      <p>
        A central driver in my life is a desire to help people live in more
        harmony with ourselves, others, and the world. I believe we can create a
        more just and caring society where people feel more unconditionally
        taken care of. I am passionate about supporting people in their journeys
        towards greater awareness, vitality, and alignment with their own
        values.
      </p>
      <blockquote className={cbtSessionsStyles.quoteBlock}>
        <p>
          <q>
            <cite>
              Wan­na fly, you got to give up the shi**t that weighs you down.
            </cite>
          </q>{" "}
          <span className={cbtSessionsStyles.quoteAuthor}>Toni Morrison</span>
        </p>
      </blockquote>
      <p>
        In my work as a therapist I use integrative Cognitive Behavioural
        Therapy (CBT), which I studied as a Diploma on top of my undergraduate
        degree in Psychology. I have benefited and continue to benefit from
        working with some great therapists myself, as I believe it is important
        for therapists to work actively on ourselves and keep that connection
        with what it is like to be a client in therapy.
      </p>
      <p>
        I am a member of the{" "}
        <a
          href=" https://www.radicaltherapistnetwork.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Radical Therapist Network (RTN)
        </a>
        , a recently founded group of psychotherapists who meet regularly to
        incorporate intersectional and anti-oppressive learning into our
        practice. I believe this helps me become a more holistic, inclusive
        therapist, and better able to take responsibility for my own privilege
        as a white, middle-class, cis-male therapist.
      </p>
      <blockquote
        className={`${cbtSessionsStyles.quoteBlock} ${aboutMeStyles.quoteAboveVideo}`}
      >
        <p>
          <q>
            <cite>
              Fight against the strong when we are weak and against ourselves
              when we are strong.
            </cite>
          </q>{" "}
          <span className={cbtSessionsStyles.quoteAuthor}>
            Lluís Maria Xirinacs
          </span>
        </p>
      </blockquote>
      <div className={aboutMeStyles.videoContainer}>
        {!vidLoaded && <Spinner />}
        <iframe
          className={aboutMeStyles.video}
          title="Gavin Lashley Therapy - An Introduction"
          src="https://player.vimeo.com/video/485165963"
          allow="autoplay; fullscreen"
          onLoad={() => setVidLoaded(true)}
        ></iframe>
      </div>

      <h3 className={aboutMeStyles.subtitle}>My Education/Qualifications</h3>
      <ul>
        <li>
          Diploma in Counselling and Groupwork - A Cognitive Behavioural
          Approach{" "}
          <span className={aboutMeStyles.parenthetic}>
            (Centre of Therapy, <i>2017</i>)
          </span>
        </li>
        <li>
          COSCA counselling skills{" "}
          <span className={aboutMeStyles.parenthetic}>
            (Centre of Therapy, <i>2015</i>)
          </span>
        </li>
        <li>
          Psychology MA 2:1 degree{" "}
          <span className={aboutMeStyles.parenthetic}>
            (Edinburgh University,
            <i> 2007</i>)
          </span>
        </li>
        <li>
          IFS Immersion: Integrating Internal Family Systems (IFS) Across Clinical Applications{" "}
          <span className={aboutMeStyles.parenthetic}>
            (<i>PESI Online Courses, 2021</i>)
          </span>
        </li>
        <li>
          Non-Violent Communication - 2-day Foundational Course{" "}
          <span className={aboutMeStyles.parenthetic}>
            (<i>2015</i>)
          </span>
        </li>
        <li>
          8-week Mindfulness Based Stress Reduction (MBSR) course{" "}
          <span className={aboutMeStyles.parenthetic}>
            (<i>2016</i>)
          </span>
        </li>
        <li>
          Rethinking Masculinities as a gender violence reduction strategy
          <span className={aboutMeStyles.parenthetic}>
            {" "}
            (<i>2015</i>)
          </span>
        </li>
        <li>
          ASIST suicide prevention 2-day course{" "}
          <span className={aboutMeStyles.parenthetic}>
            (<i>2019</i>)
          </span>
        </li>
      </ul>
      <h3
        className={`${aboutMeStyles.subtitle} ${aboutMeStyles.membershipsHeading}`}
      >
        Memberships:
      </h3>
      <ul>
        <li>
          <a
            href="https://babcp.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            British Association for Behavioural & Cognitive Psychotherapy
            (BABCP)
          </a>
        </li>
        <li>
          <a
            href=" https://www.radicaltherapistnetwork.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Radical Therapist Network
          </a>
        </li>
        <li>
          <a
            href="https://www.bacp.co.uk/"
            target="_blank"
            rel="noreferrer noopener"
          >
            British Association for Counselling and Psychotherapy (BACP)
          </a>
        </li>
        <li>
          <a
            href="https://www.balens.co.uk/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Balens Health Practitioners Insurance
          </a>
        </li>
      </ul>
    </Layout>
  )
}

export default AboutMePage
